<template>
  <div id="herramientas-proveedor">
    <div class="container-fluid" id="proveedor">
      
      <vx-card class="mb-6" >
        <div class="vx-row">
          <div class="vx-col w-full">
            <h1 class="rg-big-title extrabold rg-title">Crédito para <span class="orange">integradores</span>.</h1>
          </div>
        </div>
      </vx-card>

      <vx-card class="mb-6">
        <h3 class="mb-2">Adquiere equipos y consumibles solares con financiamiento de RedGirasol.</h3>
        <p class="bold black mb-3">Hemos ofrecido el crédito solar para tus clientes, pero hoy toca ofrecerte un crédito para ti y tu negocio.</p>
        <p class="mb-3">
            En RedGirasol tenemos un nuevo crédito dirigido a nuestra comunidad de integradores,
            con el fin de que puedas financiar la compra de tus equipos y consumibles solares para tus proyectos.
        </p>
        <p class="bold black">
            Da clic a continuación para generar tu solicitud, simular el crédito o descargar una presentación con más información.
        </p>
      </vx-card>

      <vx-card class="mb-base">
        <div class="vx-row">
          <div class="vx-col lg:w-1/2 md:w-3/4 w-full">

            <div class="vx-row">
              <div class="vx-col w-full">
                <!-- Presentación informativa -->
                <action-button
                  id="reqs-cliente"
                  title="Presentación informativa"
                  actionLink="/informativos/REDGIRASOL®_CRÉDITO PARA INSTALADORES.pdf"
                  fileName="/informativos/REDGIRASOL®_CRÉDITO PARA INSTALADORES.pdf"
                  :download="true"
                  :remoteFile="true"
                  icon="DownloadIcon"
                  color="primary"
                />
              </div>
            </div>

          </div>
        </div>

      </vx-card>

    </div>
  </div>
</template>

<script>
import ActionButton from '@components/supplier/ActionButton.vue'
export default {
  components: {
    ActionButton
  }
}
</script>
<style scoped>
  .rg-title {
      font-size: 3rem !important;
  }
</style>